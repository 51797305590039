export const BSC_SCAN_URL = 'https://bscscan.com/address/';
export const CHAIN_ID = 56;
export const RPC_URL_56 = 'https://bsc-dataseed1.binance.org';
export const POLLING_INTERVAL = 12000;

export const BISON = 'BISON';
export const BISON_IMG_URL = 'https://farms.bishares.finance/images/tokens/BISON.png';

export const biGame = 'biGAME';
export const BI_GAME_IMG_URL = 'https://app.bishares.finance/static/media/biGame.21adcd98.png';

export const biMEX = 'biMEX';
export const BI_MEX_IMG_URL = 'https://staging.bishares-main.pages.dev/static/media/biMEX.158e63a0.png';
